var exports = {};
exports = {
  A: {
    A: {
      K: 0,
      E: 0,
      F: 0.0309495,
      G: 0.041266,
      A: 0,
      B: 0.371394,
      HC: 0
    },
    B: "ms",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "HC", "K", "E", "F", "G", "A", "B", "", "", ""],
    E: "IE",
    F: {
      HC: 962323200,
      K: 998870400,
      E: 1161129600,
      F: 1237420800,
      G: 1300060800,
      A: 1346716800,
      B: 1381968000
    }
  },
  B: {
    A: {
      C: 0,
      L: 0,
      M: 0,
      H: 0,
      N: 0,
      D: 0.00477,
      O: 0.00954,
      P: 0,
      Q: 0,
      R: 0,
      S: 0,
      T: 0,
      U: 0,
      V: 0,
      W: 0,
      X: 0,
      Y: 0.00477,
      Z: 0,
      a: 0,
      b: 0.01431,
      c: 0,
      d: 0,
      e: 0,
      f: 0,
      g: 0,
      h: 0,
      i: 0,
      n: 0,
      o: 0.00477,
      p: 0.00477,
      q: 0.00477,
      r: 0,
      s: 0,
      t: 0.00477,
      u: 0.01431,
      v: 0.02385,
      w: 0.08586,
      x: 0.03339,
      y: 0.0477,
      z: 1.89369,
      j: 3.01464,
      I: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "C", "L", "M", "H", "N", "D", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z", "j", "I", "", "", ""],
    E: "Edge",
    F: {
      C: 1438128000,
      L: 1447286400,
      M: 1470096000,
      H: 1491868800,
      N: 1508198400,
      D: 1525046400,
      O: 1542067200,
      P: 1579046400,
      Q: 1581033600,
      R: 1586736000,
      S: 1590019200,
      T: 1594857600,
      U: 1598486400,
      V: 1602201600,
      W: 1605830400,
      X: 1611360000,
      Y: 1614816000,
      Z: 1618358400,
      a: 1622073600,
      b: 1626912000,
      c: 1630627200,
      d: 1632441600,
      e: 1634774400,
      f: 1637539200,
      g: 1641427200,
      h: 1643932800,
      i: 1646265600,
      n: 1649635200,
      o: 1651190400,
      p: 1653955200,
      q: 1655942400,
      r: 1659657600,
      s: 1661990400,
      t: 1664755200,
      u: 1666915200,
      v: 1670198400,
      w: 1673481600,
      x: 1675900800,
      y: 1678665600,
      z: 1680825600,
      j: 1683158400,
      I: 1685664000
    },
    D: {
      C: "ms",
      L: "ms",
      M: "ms",
      H: "ms",
      N: "ms",
      D: "ms",
      O: "ms"
    }
  },
  C: {
    A: {
      "0": 0,
      "1": 0,
      "2": 0,
      "3": 0,
      "4": 0,
      "5": 0,
      "6": 0,
      "7": 0,
      "8": 0,
      "9": 0,
      IC: 0,
      xB: 0,
      J: 0,
      K: 0,
      E: 0,
      F: 0,
      G: 0,
      A: 0,
      B: 0.00477,
      C: 0,
      L: 0,
      M: 0,
      H: 0,
      N: 0,
      D: 0,
      O: 0,
      k: 0,
      l: 0,
      AB: 0,
      BB: 0,
      CB: 0,
      DB: 0,
      EB: 0,
      FB: 0,
      GB: 0.00477,
      HB: 0,
      IB: 0,
      JB: 0,
      KB: 0,
      LB: 0,
      MB: 0,
      NB: 0.01908,
      OB: 0.00477,
      PB: 0,
      QB: 0,
      RB: 0,
      SB: 0,
      TB: 0,
      UB: 0,
      VB: 0,
      WB: 0.0477,
      XB: 0,
      YB: 0,
      ZB: 0,
      aB: 0.00477,
      bB: 0,
      cB: 0,
      yB: 0,
      dB: 0,
      zB: 0,
      eB: 0,
      fB: 0,
      gB: 0,
      hB: 0,
      iB: 0,
      jB: 0,
      kB: 0,
      lB: 0,
      mB: 0,
      nB: 0,
      oB: 0.00954,
      m: 0,
      pB: 0,
      qB: 0,
      rB: 0,
      sB: 0,
      tB: 0.03339,
      P: 0,
      Q: 0,
      R: 0,
      "0B": 0,
      S: 0,
      T: 0.00954,
      U: 0,
      V: 0,
      W: 0.01431,
      X: 0.00954,
      Y: 0,
      Z: 0,
      a: 0.00954,
      b: 0,
      c: 0,
      d: 0.00954,
      e: 0,
      f: 0,
      g: 0,
      h: 0,
      i: 0,
      n: 0,
      o: 0,
      p: 0.10971,
      q: 0.02385,
      r: 0.00477,
      s: 0.00477,
      t: 0.00477,
      u: 0.00954,
      v: 0.00954,
      w: 0.01431,
      x: 0.02385,
      y: 0.05247,
      z: 0.8109,
      j: 1.09233,
      I: 0.01431,
      "1B": 0,
      JC: 0,
      KC: 0
    },
    B: "moz",
    C: ["IC", "xB", "JC", "KC", "J", "0", "K", "E", "F", "G", "A", "B", "C", "L", "M", "H", "N", "D", "O", "1", "k", "l", "2", "3", "4", "5", "6", "7", "8", "9", "AB", "BB", "CB", "DB", "EB", "FB", "GB", "HB", "IB", "JB", "KB", "LB", "MB", "NB", "OB", "PB", "QB", "RB", "SB", "TB", "UB", "VB", "WB", "XB", "YB", "ZB", "aB", "bB", "cB", "yB", "dB", "zB", "eB", "fB", "gB", "hB", "iB", "jB", "kB", "lB", "mB", "nB", "oB", "m", "pB", "qB", "rB", "sB", "tB", "P", "Q", "R", "0B", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z", "j", "I", "1B", ""],
    E: "Firefox",
    F: {
      "0": 1308614400,
      "1": 1357603200,
      "2": 1368489600,
      "3": 1372118400,
      "4": 1375747200,
      "5": 1379376000,
      "6": 1386633600,
      "7": 1391472000,
      "8": 1395100800,
      "9": 1398729600,
      IC: 1161648000,
      xB: 1213660800,
      JC: 1246320000,
      KC: 1264032000,
      J: 1300752000,
      K: 1313452800,
      E: 1317081600,
      F: 1317081600,
      G: 1320710400,
      A: 1324339200,
      B: 1327968000,
      C: 1331596800,
      L: 1335225600,
      M: 1338854400,
      H: 1342483200,
      N: 1346112000,
      D: 1349740800,
      O: 1353628800,
      k: 1361232000,
      l: 1364860800,
      AB: 1402358400,
      BB: 1405987200,
      CB: 1409616000,
      DB: 1413244800,
      EB: 1417392000,
      FB: 1421107200,
      GB: 1424736000,
      HB: 1428278400,
      IB: 1431475200,
      JB: 1435881600,
      KB: 1439251200,
      LB: 1442880000,
      MB: 1446508800,
      NB: 1450137600,
      OB: 1453852800,
      PB: 1457395200,
      QB: 1461628800,
      RB: 1465257600,
      SB: 1470096000,
      TB: 1474329600,
      UB: 1479168000,
      VB: 1485216000,
      WB: 1488844800,
      XB: 1492560000,
      YB: 1497312000,
      ZB: 1502150400,
      aB: 1506556800,
      bB: 1510617600,
      cB: 1516665600,
      yB: 1520985600,
      dB: 1525824000,
      zB: 1529971200,
      eB: 1536105600,
      fB: 1540252800,
      gB: 1544486400,
      hB: 1548720000,
      iB: 1552953600,
      jB: 1558396800,
      kB: 1562630400,
      lB: 1567468800,
      mB: 1571788800,
      nB: 1575331200,
      oB: 1578355200,
      m: 1581379200,
      pB: 1583798400,
      qB: 1586304000,
      rB: 1588636800,
      sB: 1591056000,
      tB: 1593475200,
      P: 1595894400,
      Q: 1598313600,
      R: 1600732800,
      "0B": 1603152000,
      S: 1605571200,
      T: 1607990400,
      U: 1611619200,
      V: 1614038400,
      W: 1616457600,
      X: 1618790400,
      Y: 1622505600,
      Z: 1626134400,
      a: 1628553600,
      b: 1630972800,
      c: 1633392000,
      d: 1635811200,
      e: 1638835200,
      f: 1641859200,
      g: 1644364800,
      h: 1646697600,
      i: 1649116800,
      n: 1651536000,
      o: 1653955200,
      p: 1656374400,
      q: 1658793600,
      r: 1661212800,
      s: 1663632000,
      t: 1666051200,
      u: 1668470400,
      v: 1670889600,
      w: 1673913600,
      x: 1676332800,
      y: 1678752000,
      z: 1681171200,
      j: 1683590400,
      I: null,
      "1B": null
    }
  },
  D: {
    A: {
      "0": 0,
      "1": 0,
      "2": 0,
      "3": 0,
      "4": 0,
      "5": 0,
      "6": 0,
      "7": 0,
      "8": 0,
      "9": 0,
      J: 0,
      K: 0,
      E: 0,
      F: 0,
      G: 0,
      A: 0,
      B: 0,
      C: 0,
      L: 0,
      M: 0,
      H: 0,
      N: 0,
      D: 0,
      O: 0,
      k: 0,
      l: 0,
      AB: 0,
      BB: 0,
      CB: 0,
      DB: 0,
      EB: 0.00954,
      FB: 0.00477,
      GB: 0,
      HB: 0,
      IB: 0.01431,
      JB: 0,
      KB: 0.00954,
      LB: 0,
      MB: 0,
      NB: 0.00954,
      OB: 0.00477,
      PB: 0.00954,
      QB: 0,
      RB: 0.00954,
      SB: 0.01908,
      TB: 0.03339,
      UB: 0.00954,
      VB: 0,
      WB: 0.00477,
      XB: 0.00954,
      YB: 0,
      ZB: 0.00477,
      aB: 0.06678,
      bB: 0,
      cB: 0.00477,
      yB: 0,
      dB: 0.00954,
      zB: 0.01431,
      eB: 0,
      fB: 0.00477,
      gB: 0,
      hB: 0.00954,
      iB: 0.02862,
      jB: 0.00954,
      kB: 0.00954,
      lB: 0.03816,
      mB: 0.01908,
      nB: 0.00954,
      oB: 0.01908,
      m: 0.01431,
      pB: 0.03339,
      qB: 0.07155,
      rB: 0.05724,
      sB: 0.01908,
      tB: 0.02385,
      P: 0.2385,
      Q: 0.04293,
      R: 0.04293,
      S: 0.15264,
      T: 0.01908,
      U: 0.06678,
      V: 0.05247,
      W: 0.10494,
      X: 0.02862,
      Y: 0.03339,
      Z: 0.0477,
      a: 0.08109,
      b: 0.0477,
      c: 0.13356,
      d: 0.03816,
      e: 0.01908,
      f: 0.03339,
      g: 0.02862,
      h: 0.05247,
      i: 0.05724,
      n: 0.04293,
      o: 0.04293,
      p: 0.05724,
      q: 0.25758,
      r: 0.05724,
      s: 0.08109,
      t: 0.05724,
      u: 0.07632,
      v: 0.21465,
      w: 2.11788,
      x: 0.20988,
      y: 0.80613,
      z: 6.99759,
      j: 12.3829,
      I: 0.07155,
      "1B": 0.01908,
      LC: 0,
      MC: 0
    },
    B: "webkit",
    C: ["", "", "", "", "J", "0", "K", "E", "F", "G", "A", "B", "C", "L", "M", "H", "N", "D", "O", "1", "k", "l", "2", "3", "4", "5", "6", "7", "8", "9", "AB", "BB", "CB", "DB", "EB", "FB", "GB", "HB", "IB", "JB", "KB", "LB", "MB", "NB", "OB", "PB", "QB", "RB", "SB", "TB", "UB", "VB", "WB", "XB", "YB", "ZB", "aB", "bB", "cB", "yB", "dB", "zB", "eB", "fB", "gB", "hB", "iB", "jB", "kB", "lB", "mB", "nB", "oB", "m", "pB", "qB", "rB", "sB", "tB", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z", "j", "I", "1B", "LC", "MC"],
    E: "Chrome",
    F: {
      "0": 1274745600,
      "1": 1332892800,
      "2": 1343692800,
      "3": 1348531200,
      "4": 1352246400,
      "5": 1357862400,
      "6": 1361404800,
      "7": 1364428800,
      "8": 1369094400,
      "9": 1374105600,
      J: 1264377600,
      K: 1283385600,
      E: 1287619200,
      F: 1291248000,
      G: 1296777600,
      A: 1299542400,
      B: 1303862400,
      C: 1307404800,
      L: 1312243200,
      M: 1316131200,
      H: 1316131200,
      N: 1319500800,
      D: 1323734400,
      O: 1328659200,
      k: 1337040000,
      l: 1340668800,
      AB: 1376956800,
      BB: 1384214400,
      CB: 1389657600,
      DB: 1392940800,
      EB: 1397001600,
      FB: 1400544000,
      GB: 1405468800,
      HB: 1409011200,
      IB: 1412640000,
      JB: 1416268800,
      KB: 1421798400,
      LB: 1425513600,
      MB: 1429401600,
      NB: 1432080000,
      OB: 1437523200,
      PB: 1441152000,
      QB: 1444780800,
      RB: 1449014400,
      SB: 1453248000,
      TB: 1456963200,
      UB: 1460592000,
      VB: 1464134400,
      WB: 1469059200,
      XB: 1472601600,
      YB: 1476230400,
      ZB: 1480550400,
      aB: 1485302400,
      bB: 1489017600,
      cB: 1492560000,
      yB: 1496707200,
      dB: 1500940800,
      zB: 1504569600,
      eB: 1508198400,
      fB: 1512518400,
      gB: 1516752000,
      hB: 1520294400,
      iB: 1523923200,
      jB: 1527552000,
      kB: 1532390400,
      lB: 1536019200,
      mB: 1539648000,
      nB: 1543968000,
      oB: 1548720000,
      m: 1552348800,
      pB: 1555977600,
      qB: 1559606400,
      rB: 1564444800,
      sB: 1568073600,
      tB: 1571702400,
      P: 1575936000,
      Q: 1580860800,
      R: 1586304000,
      S: 1589846400,
      T: 1594684800,
      U: 1598313600,
      V: 1601942400,
      W: 1605571200,
      X: 1611014400,
      Y: 1614556800,
      Z: 1618272000,
      a: 1621987200,
      b: 1626739200,
      c: 1630368000,
      d: 1632268800,
      e: 1634601600,
      f: 1637020800,
      g: 1641340800,
      h: 1643673600,
      i: 1646092800,
      n: 1648512000,
      o: 1650931200,
      p: 1653350400,
      q: 1655769600,
      r: 1659398400,
      s: 1661817600,
      t: 1664236800,
      u: 1666656000,
      v: 1669680000,
      w: 1673308800,
      x: 1675728000,
      y: 1678147200,
      z: 1680566400,
      j: 1682985600,
      I: 1685404800,
      "1B": null,
      LC: null,
      MC: null
    }
  },
  E: {
    A: {
      "0": 0,
      J: 0,
      K: 0,
      E: 0,
      F: 0,
      G: 0,
      A: 0,
      B: 0,
      C: 0,
      L: 0.02385,
      M: 0.12879,
      H: 0.03339,
      D: 0,
      NC: 0,
      "2B": 0,
      OC: 0.00954,
      PC: 0,
      QC: 0,
      RC: 0.0477,
      "3B": 0,
      uB: 0.00477,
      vB: 0.0477,
      "4B": 0.18126,
      SC: 0.37206,
      TC: 0.05724,
      "5B": 0.0477,
      "6B": 0.11925,
      "7B": 0.20988,
      "8B": 0.86337,
      wB: 0.09063,
      "9B": 0.29574,
      AC: 0.35775,
      BC: 0.98262,
      CC: 1.86984,
      DC: 0.14787,
      EC: 0,
      UC: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "NC", "2B", "J", "0", "OC", "K", "PC", "E", "QC", "F", "G", "RC", "A", "3B", "B", "uB", "C", "vB", "L", "4B", "M", "SC", "H", "TC", "5B", "6B", "7B", "8B", "wB", "9B", "AC", "BC", "CC", "DC", "EC", "D", "UC"],
    E: "Safari",
    F: {
      "0": 1275868800,
      NC: 1205798400,
      "2B": 1226534400,
      J: 1244419200,
      OC: 1311120000,
      K: 1343174400,
      PC: 1382400000,
      E: 1382400000,
      QC: 1410998400,
      F: 1413417600,
      G: 1443657600,
      RC: 1458518400,
      A: 1474329600,
      "3B": 1490572800,
      B: 1505779200,
      uB: 1522281600,
      C: 1537142400,
      vB: 1553472000,
      L: 1568851200,
      "4B": 1585008000,
      M: 1600214400,
      SC: 1619395200,
      H: 1632096000,
      TC: 1635292800,
      "5B": 1639353600,
      "6B": 1647216000,
      "7B": 1652745600,
      "8B": 1658275200,
      wB: 1662940800,
      "9B": 1666569600,
      AC: 1670889600,
      BC: 1674432000,
      CC: 1679875200,
      DC: 1684368000,
      EC: null,
      D: null,
      UC: null
    }
  },
  F: {
    A: {
      "1": 0,
      "2": 0,
      "3": 0,
      "4": 0,
      "5": 0,
      "6": 0,
      "7": 0,
      "8": 0.00954,
      "9": 0,
      G: 0,
      B: 0.03816,
      C: 0,
      H: 0,
      N: 0,
      D: 0,
      O: 0,
      k: 0,
      l: 0,
      AB: 0,
      BB: 0,
      CB: 0,
      DB: 0,
      EB: 0,
      FB: 0,
      GB: 0,
      HB: 0,
      IB: 0,
      JB: 0,
      KB: 0.00477,
      LB: 0,
      MB: 0,
      NB: 0,
      OB: 0,
      PB: 0,
      QB: 0.01431,
      RB: 0,
      SB: 0,
      TB: 0,
      UB: 0,
      VB: 0,
      WB: 0,
      XB: 0,
      YB: 0,
      ZB: 0,
      aB: 0,
      bB: 0,
      cB: 0,
      dB: 0,
      eB: 0,
      fB: 0,
      gB: 0,
      hB: 0,
      iB: 0,
      jB: 0,
      kB: 0,
      lB: 0,
      mB: 0,
      nB: 0,
      oB: 0,
      m: 0,
      pB: 0,
      qB: 0,
      rB: 0,
      sB: 0,
      tB: 0,
      P: 0,
      Q: 0,
      R: 0,
      "0B": 0,
      S: 0,
      T: 0,
      U: 0.00477,
      V: 0,
      W: 0,
      X: 0,
      Y: 0.01431,
      Z: 0,
      a: 0,
      b: 0,
      c: 0,
      d: 0.00477,
      e: 0.06201,
      f: 0.01908,
      g: 0.45315,
      h: 1.16388,
      i: 0.02385,
      VC: 0,
      WC: 0,
      XC: 0,
      YC: 0,
      uB: 0,
      FC: 0,
      ZC: 0,
      vB: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "G", "VC", "WC", "XC", "YC", "B", "uB", "FC", "ZC", "C", "vB", "H", "N", "D", "O", "1", "k", "l", "2", "3", "4", "5", "6", "7", "8", "9", "AB", "BB", "CB", "DB", "EB", "FB", "GB", "HB", "IB", "JB", "KB", "LB", "MB", "NB", "OB", "PB", "QB", "RB", "SB", "TB", "UB", "VB", "WB", "XB", "YB", "ZB", "aB", "bB", "cB", "dB", "eB", "fB", "gB", "hB", "iB", "jB", "kB", "lB", "mB", "nB", "oB", "m", "pB", "qB", "rB", "sB", "tB", "P", "Q", "R", "0B", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "", "", ""],
    E: "Opera",
    F: {
      "1": 1390867200,
      "2": 1401753600,
      "3": 1405987200,
      "4": 1409616000,
      "5": 1413331200,
      "6": 1417132800,
      "7": 1422316800,
      "8": 1425945600,
      "9": 1430179200,
      G: 1150761600,
      VC: 1223424000,
      WC: 1251763200,
      XC: 1267488000,
      YC: 1277942400,
      B: 1292457600,
      uB: 1302566400,
      FC: 1309219200,
      ZC: 1323129600,
      C: 1323129600,
      vB: 1352073600,
      H: 1372723200,
      N: 1377561600,
      D: 1381104000,
      O: 1386288000,
      k: 1393891200,
      l: 1399334400,
      AB: 1433808000,
      BB: 1438646400,
      CB: 1442448000,
      DB: 1445904000,
      EB: 1449100800,
      FB: 1454371200,
      GB: 1457308800,
      HB: 1462320000,
      IB: 1465344000,
      JB: 1470096000,
      KB: 1474329600,
      LB: 1477267200,
      MB: 1481587200,
      NB: 1486425600,
      OB: 1490054400,
      PB: 1494374400,
      QB: 1498003200,
      RB: 1502236800,
      SB: 1506470400,
      TB: 1510099200,
      UB: 1515024000,
      VB: 1517961600,
      WB: 1521676800,
      XB: 1525910400,
      YB: 1530144000,
      ZB: 1534982400,
      aB: 1537833600,
      bB: 1543363200,
      cB: 1548201600,
      dB: 1554768000,
      eB: 1561593600,
      fB: 1566259200,
      gB: 1570406400,
      hB: 1573689600,
      iB: 1578441600,
      jB: 1583971200,
      kB: 1587513600,
      lB: 1592956800,
      mB: 1595894400,
      nB: 1600128000,
      oB: 1603238400,
      m: 1613520000,
      pB: 1612224000,
      qB: 1616544000,
      rB: 1619568000,
      sB: 1623715200,
      tB: 1627948800,
      P: 1631577600,
      Q: 1633392000,
      R: 1635984000,
      "0B": 1638403200,
      S: 1642550400,
      T: 1644969600,
      U: 1647993600,
      V: 1650412800,
      W: 1652745600,
      X: 1654646400,
      Y: 1657152000,
      Z: 1660780800,
      a: 1663113600,
      b: 1668816000,
      c: 1668643200,
      d: 1671062400,
      e: 1675209600,
      f: 1677024000,
      g: 1679529600,
      h: 1681948800,
      i: 1684195200
    },
    D: {
      G: "o",
      B: "o",
      C: "o",
      VC: "o",
      WC: "o",
      XC: "o",
      YC: "o",
      uB: "o",
      FC: "o",
      ZC: "o",
      vB: "o"
    }
  },
  G: {
    A: {
      F: 0,
      D: 0,
      "2B": 0,
      aC: 0,
      GC: 0.00307706,
      bC: 0.00307706,
      cC: 0.00461559,
      dC: 0.0153853,
      eC: 0.00923118,
      fC: 0.00923118,
      gC: 0.0476944,
      hC: 0.00307706,
      iC: 0.0630797,
      jC: 0.0215394,
      kC: 0.0200009,
      lC: 0.0169238,
      mC: 0.341554,
      nC: 0.00923118,
      oC: 0.0138468,
      pC: 0.026155,
      qC: 0.0738494,
      rC: 0.204624,
      sC: 0.380017,
      tC: 0.116928,
      "5B": 0.143083,
      "6B": 0.164623,
      "7B": 0.256934,
      "8B": 0.667722,
      wB: 0.776957,
      "9B": 1.4816,
      AC: 0.821575,
      BC: 2.3401,
      CC: 6.00488,
      DC: 0.430788,
      EC: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "2B", "aC", "GC", "bC", "cC", "dC", "F", "eC", "fC", "gC", "hC", "iC", "jC", "kC", "lC", "mC", "nC", "oC", "pC", "qC", "rC", "sC", "tC", "5B", "6B", "7B", "8B", "wB", "9B", "AC", "BC", "CC", "DC", "EC", "D", ""],
    E: "Safari on iOS",
    F: {
      "2B": 1270252800,
      aC: 1283904000,
      GC: 1299628800,
      bC: 1331078400,
      cC: 1359331200,
      dC: 1394409600,
      F: 1410912000,
      eC: 1413763200,
      fC: 1442361600,
      gC: 1458518400,
      hC: 1473724800,
      iC: 1490572800,
      jC: 1505779200,
      kC: 1522281600,
      lC: 1537142400,
      mC: 1553472000,
      nC: 1568851200,
      oC: 1572220800,
      pC: 1580169600,
      qC: 1585008000,
      rC: 1600214400,
      sC: 1619395200,
      tC: 1632096000,
      "5B": 1639353600,
      "6B": 1647216000,
      "7B": 1652659200,
      "8B": 1658275200,
      wB: 1662940800,
      "9B": 1666569600,
      AC: 1670889600,
      BC: 1674432000,
      CC: 1679875200,
      DC: 1684368000,
      EC: null,
      D: null
    }
  },
  H: {
    A: {
      uC: 0.886305
    },
    B: "o",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "uC", "", "", ""],
    E: "Opera Mini",
    F: {
      uC: 1426464000
    }
  },
  I: {
    A: {
      xB: 0,
      J: 0.0144972,
      I: 0,
      vC: 0,
      wC: 0.0579888,
      xC: 0,
      yC: 0.0144972,
      GC: 0.0797346,
      zC: 0,
      "0C": 0.246452
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "vC", "wC", "xC", "xB", "J", "yC", "GC", "zC", "0C", "I", "", "", ""],
    E: "Android Browser",
    F: {
      vC: 1256515200,
      wC: 1274313600,
      xC: 1291593600,
      xB: 1298332800,
      J: 1318896000,
      yC: 1341792000,
      GC: 1374624000,
      zC: 1386547200,
      "0C": 1401667200,
      I: 1685404800
    }
  },
  J: {
    A: {
      E: 0,
      A: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "E", "A", "", "", ""],
    E: "Blackberry Browser",
    F: {
      E: 1325376000,
      A: 1359504000
    }
  },
  K: {
    A: {
      A: 0,
      B: 0,
      C: 0,
      m: 0,
      uB: 0,
      FC: 0,
      vB: 0
    },
    B: "o",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "A", "B", "uB", "FC", "C", "vB", "m", "", "", ""],
    E: "Opera Mobile",
    F: {
      A: 1287100800,
      B: 1300752000,
      uB: 1314835200,
      FC: 1318291200,
      C: 1330300800,
      vB: 1349740800,
      m: 1673827200
    },
    D: {
      m: "webkit"
    }
  },
  L: {
    A: {
      I: 37.5965
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "I", "", "", ""],
    E: "Chrome for Android",
    F: {
      I: 1685404800
    }
  },
  M: {
    A: {
      j: 0.27719
    },
    B: "moz",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "j", "", "", ""],
    E: "Firefox for Android",
    F: {
      j: 1683590400
    }
  },
  N: {
    A: {
      A: 0,
      B: 0
    },
    B: "ms",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "A", "B", "", "", ""],
    E: "IE Mobile",
    F: {
      A: 1340150400,
      B: 1353456000
    }
  },
  O: {
    A: {
      "1C": 0.80019
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "1C", "", "", ""],
    E: "UC Browser for Android",
    F: {
      "1C": 1634688000
    },
    D: {
      "1C": "webkit"
    }
  },
  P: {
    A: {
      J: 0.157218,
      k: 1.66651,
      l: 0.408766,
      "2C": 0,
      "3C": 0,
      "4C": 0.0524059,
      "5C": 0,
      "6C": 0,
      "3B": 0,
      "7C": 0.0209623,
      "8C": 0,
      "9C": 0.0209623,
      AD: 0.0104812,
      BD: 0.0104812,
      wB: 0.0419247,
      CD: 0.0524059,
      DD: 0.0419247,
      ED: 0.104812
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "J", "2C", "3C", "4C", "5C", "6C", "3B", "7C", "8C", "9C", "AD", "BD", "wB", "CD", "DD", "ED", "k", "l", "", "", ""],
    E: "Samsung Internet",
    F: {
      J: 1461024000,
      "2C": 1481846400,
      "3C": 1509408000,
      "4C": 1528329600,
      "5C": 1546128000,
      "6C": 1554163200,
      "3B": 1567900800,
      "7C": 1582588800,
      "8C": 1593475200,
      "9C": 1605657600,
      AD: 1618531200,
      BD: 1629072000,
      wB: 1640736000,
      CD: 1651708800,
      DD: 1659657600,
      ED: 1667260800,
      k: 1677369600,
      l: 1684454400
    }
  },
  Q: {
    A: {
      "4B": 0.12552
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "4B", "", "", ""],
    E: "QQ Browser",
    F: {
      "4B": 1663718400
    }
  },
  R: {
    A: {
      FD: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "FD", "", "", ""],
    E: "Baidu Browser",
    F: {
      FD: 1663027200
    }
  },
  S: {
    A: {
      GD: 0.1046,
      HD: 0
    },
    B: "moz",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "GD", "HD", "", "", ""],
    E: "KaiOS Browser",
    F: {
      GD: 1527811200,
      HD: 1631664000
    }
  }
};
export default exports;